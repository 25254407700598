import React from 'react'
import SEO from '../components/seo'
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-custom'
import Footer from '../containers/layout/footer/footer-custom'
import PageHeader from '../components/pageheader'
import { IntroArea, FocusArea } from '../containers/sell-forum'
import ContactFormArea from '../containers/contact-form-area'
import { useIntl } from 'gatsby-plugin-react-intl'

const SellForumPage = ({ pageContext, location }) => {
    const intl = useIntl()

    return (
        <Layout location={location}>
            <SEO
                title={intl.formatMessage({
                    id: 'page.sellForum.title',
                })}
            />
            <Header />
            <PageHeader
                pageContext={pageContext}
                location={location}
                title={intl.formatMessage({
                    id: 'page.sellForum.title',
                })}
            />
            <IntroArea />
            <FocusArea />
            <main className="site-wrapper-reveal">
                <ContactFormArea
                    titleID="page.sellForum.contact.title"
                    descID="page.sellForum.contact.desc"
                />
            </main>
            <Footer />
        </Layout>
    )
}

export default SellForumPage
