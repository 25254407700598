import styled from 'styled-components'
import { device } from '../../../theme'

export const IntroWrap = styled.div`
    padding-top: 100px;
    padding-bottom: 100px;
    @media ${device.medium} {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @media ${device.small} {
        padding-top: 60px;
        padding-bottom: 60px;
    }
`

export const ImageWrap = styled.div`
    text-align: right;
    @media ${device.medium} {
        margin-top: 30px;
    }
`

export const IntroTextWrap = styled.div`
    max-width: 500px;
    width: 100%;
    margin: auto;
`

export const ButtonWrap = styled.div`
    margin-top: 34px;
`
