import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'
import parse from 'html-react-parser'
import { useStaticQuery, graphql } from 'gatsby'
import Heading from '../../../components/ui/heading'
import Text from '../../../components/ui/text'
import Image from '../../../components/image'
import { AreaWrap, ImageWrap, AreaTextWrap } from './focus-area.style'
import { useIntl } from 'gatsby-plugin-react-intl'

export const FocusArea = ({ titleStyle, textStyle }) => {
    const intl = useIntl()
    const focusData = useStaticQuery(graphql`
        query SellForumFocusQuery {
            sellForumJson(id: { eq: "focus" }) {
                image {
                    childImageSharp {
                        fluid(maxWidth: 922, maxHeight: 760, quality: 100) {
                            presentationWidth
                            presentationHeight
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    `)
    const data = focusData.sellForumJson
    const imageFluid = data.image.childImageSharp.fluid

    return (
        <AreaWrap>
            <Container>
                <Row className="align-items-center flex-lg-row-reverse">
                    <Col lg={6}>
                        <AreaTextWrap>
                            <Heading {...titleStyle}>
                                {parse(
                                    intl.formatMessage({
                                        id: 'page.sellForum.focus.title',
                                    })
                                )}
                            </Heading>
                            <Text {...textStyle}>
                                {parse(
                                    intl.formatMessage({
                                        id: 'page.sellForum.focus.desc',
                                    })
                                )}
                            </Text>
                        </AreaTextWrap>
                    </Col>
                    <Col lg={6}>
                        <ImageWrap>
                            <Image alt={data.title} fluid={imageFluid} />
                        </ImageWrap>
                    </Col>
                </Row>
            </Container>
        </AreaWrap>
    )
}

FocusArea.propTypes = {
    titleStyle: PropTypes.object,
}

FocusArea.defaultProps = {
    titleStyle: {
        as: 'h4',
        child: {
            color: 'primary',
        },
    },
    textStyle: {
        mt: '30px',
        fontSize: '16px',
    },
}
